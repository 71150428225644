@import 'abstracts';

$add-action-button-width: 100px;

body .cpb-list-renderer {
  padding: 15px;
}

body .p-paginator {
  .p-paginator-first,
  .p-paginator-last {
    display: inline-flex !important;
    background: $white;
    border: 1px solid $alto;

    :not(.p-disabled):not(.p-highlight):hover {
      background-color: $paginator-background;
      color: var(--primary-text);
    }

    :not(.p-disabled) {
      color: var(--cap-primary-color);
    }

    &.p-disabled {
      span {
        color: $paginator-icon;
      }
    }
  }

  .p-paginator-first {
    border-right: none;
  }

  .p-paginator-last {
    border-left: none;
  }
}

cpb-list-renderer-header-actions {
  .header-action__items li {
    cap-button {
      p-button {
        button.cap-button.secondary {
          color: $white;
          background-color: var(--cap-primary-color);

          &:disabled {
            background-color: $cap-button-disabled-background;
            box-shadow: none;
          }
        }
      }
    }
  }
}

cpb-list-renderer-filters {
  .table-filters.cap-table-filter {
    justify-content: start;
    align-items: unset;

    .p-placeholder,
    ::-webkit-input-placeholder {
      color: $understated-text-color !important;
      font-size: $filter-font-size;
      font-weight: 600;
    }

    .cap-multi-select.cap-rounded, .cap-calendar.cap-rounded {
      border-color: $border-color;
      font-size: $filter-font-size;

      input.p-inputtext.p-component {
        border-color: $border-color;
      }

      &:hover {
        padding: 0;
        font-size: $filter-font-size;

        input.p-inputtext.p-component {
          border-radius: 20px;
          position: relative;
          left: 0;
        }
      }
    }

    input.cap-rounded {
      border: 1px solid $borders-color;
      font-size: $filter-font-size;
      min-height: 33px !important; /* stylelint-disable-line declaration-no-important */

      &:hover {
        border-color: $borders-color;
      }

      &::placeholder {
        color: $understated-text-color;
        font-size: $filter-font-size;
        font-weight: 600;
      }
    }

    .p-datepicker-trigger {
      display: none;
    }

    .p-multiselect-label {
      font-size: $filter-font-size;
      padding-left: 10px !important;
    }

    .cap-dropdown.cap-rounded {
      border: 1px solid $borders-color;
      font-size: $filter-font-size !important;

      .p-dropdown-label {
        padding-top: 5px;
        background-color: transparent;
      }

      .p-dropdown-trigger-icon, .p-multiselect-trigger-icon {
        &:before {
          margin-right: 8px;
        }
      }
    }
  }

  div.actions {
    @media screen and (max-width: 1400px) {
      & cap-button:last-child {
        margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
      }
    }

    .round {
      padding: 2px 20px;

      .p-button-label {
        font-size: 14px;
      }
    }
  }
}

cpb-list-renderer[key='list_installers'], cpb-list-renderer[key='list_distributor_installers'] {
  cap-info-table-header {
    tr {
      th:first-child {
        width: 70px;
      }
    }
  }
}

cpb-list-renderer[key='list_distributor_installers'] {
  thead {
    display: none;
  }

  tr.cap-info-table__row {
    border: 1px solid $borders-color;
  }

  .cap-info-table__column-category {
    width: 80px;
  }

  cap-cell-data {
    color: $body-color;
    font-size: 14px;

    app-installer-category-widget {
      i {
        font-size: 20px;
        display: flex;
        padding: 0;
        justify-content: center;
      }
    }
  }

  cpb-list-renderer-filters[ng-reflect-key='list_distributor_installers'] {
    .table-filters .filters .table-filter-row .filter {
      width: 33%;
    }
  }
}

cpb-list-renderer-filters[ng-reflect-key='list_installers'] {
  .table-filters.cap-table-filter {
    max-width: calc(100% - #{$add-action-button-width});
  }
}

.cap-info-table.p-datatable .p-datatable-thead th {
  overflow: hidden;
  text-overflow: ellipsis;
}

cpb-list-renderer, app-table-filter {
  .cap-dropdown-panel.p-dropdown-panel,
  .cap-multi-select-panel,
  .p-datepicker:not(.p-datepicker-inline) {
    z-index: 998 !important; // Important is needed because it sets a style on the element itself
  }
}

@include cap-media-breakpoint(handset) {
  body div.p-dialog.list-renderer-filters-dialog {
    padding: 0;

    .p-dropdown-filter,
    .p-multiselect-label {
      text-indent: unset;
    }

    .p-dialog-header-icons {
      top: 15px;
      left: 15px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .cpb-list-renderer {
    .header {
      max-width: 70%;
    }
  }
}

.cpb-header-actions {
  position: absolute;
  right: 15px;
  width: 30%;
}

cpb-list-renderer-filters[ng-reflect-key='list_installers'],
cpb-list-renderer-filters[ng-reflect-key='list_leads'] {
  cap-multi-select.has-icon
  .p-multiselect
  .p-multiselect-label {
    padding-left: 0;
  }

  .table-filters-items .table-filter-row .filter cap-multi-select p-multiSelect
  .p-multiselect-label-container {
    padding-left: 6px !important;
  }
}

@include media-breakpoint-down(md) {
  .cpb-header-actions {
    position: relative;
    width: 100%;

    .action-panel .header-action__items {
      flex-direction: row;
    }
  }
}
