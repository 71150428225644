@import '~bootstrap/scss/bootstrap-grid';
@import 'abstracts';

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1980px;
  }

  #headerContainer.container {
    height: $menu-height;
    max-width: 1730px;
  }
}
