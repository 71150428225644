.p-datepicker table td {
  span {
    margin: 1px;
  }
}
.p-datepicker-header {
  padding-bottom: 8px;
}

.p-datepicker-buttonbar {
  margin-top: 6px;
  padding-top: 4px;
}

.p-datepicker.p-component {
  top: 33px !important; /* stylelint-disable-line declaration-no-important */
}
